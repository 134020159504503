/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Social from "./social";

import "../styles/layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-half is-offset-half">
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot has-text-right">
          <div className="container">
            <Social />
          </div>
        </div>
      </section>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
