import React from "react"

import '../styles/social.css';

const Social = () => (
  <>
    <ul className="social">
      {/* <li>
        <!-- Place this tag where you want the share button to render. -->
        <div className="g-plus" data-action="share" data-annotation="none"></div>

        <!-- Place this tag after the last share tag. -->
        <script type="text/javascript">
          (function() {
            var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
            po.src = 'https://apis.google.com/js/plusone.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
          })();
        </script>
      </li> */}

      {/* <li>
        <a href="https://twitter.com/share" className="twitter-share-button" data-text="Резникова Надежда - психотерапевт-гипнотерапевт в #Ванкувер" data-via="nedbright" data-count="none">Tweet</a>
        <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src="//platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>
      </li> */}

      {/* <li>
        <script type="text/javascript" src="http://vkontakte.ru/js/api/share.js?9" charset="windows-1251"></script>
        <script type="text/javascript">
        <!--
        document.write(VK.Share.button());
        -->
        </script>
      </li> */}

      {/* <li>
          <iframe
            src="//www.facebook.com/plugins/like.php?href=http%3A%2F%2Fnedbright.com%2F&amp;send=false&amp;layout=button_count&amp;width=100&amp;show_faces=false&amp;action=recommend&amp;colorscheme=light&amp;font&amp;height=21&amp;appId=151931338221566"
            scrolling="no"
            frameborder="0"
            style={{border: 'none', overflow: 'hidden', width: 150, height: 21}}
            allowTransparency="true"
          />
      </li> */}
    </ul>
  </>
);

export default Social;
